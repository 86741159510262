/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin, FaYoutubeSquare, FaInstagramSquare } from 'react-icons/fa';
import { BsBoxArrowInRight, BsWhatsapp } from 'react-icons/bs';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineLeft } from 'react-icons/ai';
import { GoMail } from 'react-icons/go';
import FirstAcessService from "../services/FirstAcessService";
import ModalFirstAccess from "./ModalFirstAccess";

interface UserFirtsAccess {
    name?: string;
    cpf?: string;
}

const FirstAccessSide = (props: any) => {
    const [isArrowUp, setIsArrowUp] = useState(false);
    const [isArrowUpIcon, setIsArrowUpIcon] = useState(false);
    const [isTypeRender, setIsTypeRender] = useState(0);
    const [recaptchaResponse, setRecaptchaResponse] = useState(false);
    const [modelFirstAccess, setModelFirstAccess] = useState<UserFirtsAccess>();
    const [responseDataStudent, setResponseDataStudent] = useState<UserFirtsAccess>();
    const [validated, setValidated] = useState(false);
    const [disabledBtnSubmit, setDisabledBtnSubmit] = useState(true);
    const [showModalStudent, setShowModalStudent] = useState(false);
    const [loadGetStudentApi, setLoadGetStudentApi] = useState(true);

    useEffect(() => {
        if (modelFirstAccess?.cpf && modelFirstAccess.name) {
            if (modelFirstAccess.cpf.length === 14 && modelFirstAccess.name.length >= 3 && recaptchaResponse) {
                setDisabledBtnSubmit(false)
            }
        }
    }, [modelFirstAccess, recaptchaResponse])

    useEffect(() => {
        setRecaptchaResponse(false)
    }, [])

    useEffect(() => {
        if (props.showForm) {
            //@ts-ignore
            grecaptcha.render("recaptcha", {
                sitekey: '6LfeG0ImAAAAAOKbRNgZnLNCBBQa0RD6KFJLmBdd',
                callback: function () {
                    setRecaptchaResponse(!recaptchaResponse)
                }
            });

            const tamanho: any = document.getElementsByClassName('div-btn-actions')[0];
            props.setNextHeight(tamanho?.offsetHeight)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showForm])

    const handleInputChange = (e: any) => {
        const field = e.id;
        let value = e.value;

        if (field === "cpf") {
            value = value.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1');
        }
        const updatedModelFirstAccess: UserFirtsAccess = {
            ...modelFirstAccess,
            [field]: value,
        };

        setModelFirstAccess(updatedModelFirstAccess);
    }

    const handleButtonClick = () => {
        setIsTypeRender(1);
        if (props.showFAQ && isArrowUp && isTypeRender !== 1) {
            setIsArrowUp(true)
            setIsArrowUpIcon(true)
        } else {
            setIsArrowUp(!isArrowUp);
            setIsArrowUpIcon(!isArrowUp);
        }
    };

    const handleButtonFAQClick = () => {
        setIsTypeRender(2);
        if (props.showFAQ && isTypeRender !== 2) {
            setIsArrowUp(true);
        } else {
            setIsArrowUp(true);
            props.setShowFAQ(true);
        }
    };

    const switchRenderMenu = (type: number) => {
        switch (type) {
            case 1:
                return (
                    <>
                        <li>
                            <a className="link-suporte" href="mailto:suporte.digital@rj.senac.br" target="_blank" rel="noreferrer">
                                <GoMail className="link-suporte-icone" />E-mail - suporte.digital@rj.senac.br
                            </a>
                        </li><li>
                            <a className="link-suporte" href="https://whatsa.me/5521995379347" target="_blank" rel="noreferrer">
                                <BsWhatsapp className="link-suporte-icone" />WhatsApp - 21 99537-9347
                            </a>
                        </li>
                    </>
                );
            case 2:
                return (
                    <>
                        <li>
                            <a className="link-suporte" href="#Acessos">
                                Acessos
                            </a>
                        </li>
                        <li>
                            <a className="link-suporte" href="#Autenticacao">
                                Autenticação
                            </a>
                        </li>
                        <li>
                            <a className="link-suporte" href="#Tutoriais">
                                Tutoriais
                            </a>
                        </li>
                    </>
                );
        }
    }

    const handleClose = () => {
        setShowModalStudent(false);
        const updatedModelFirstAccess: UserFirtsAccess = {
            name: '',
            cpf: ''
        };

        setModelFirstAccess(updatedModelFirstAccess);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setShowModalStudent(true)
            if (recaptchaResponse && modelFirstAccess) {
                const getDataStudent = await new FirstAcessService().getStudentApi(modelFirstAccess);
                setResponseDataStudent(getDataStudent);
                setLoadGetStudentApi(false)
            }
        }
        setValidated(true);

        return false;
    };

    return (
        <Col lg={4} md={5} sm={6} className="div-links">
            <div className="div-logo-links d-flex">
                <div className="div-log-top">
                    <img src={require('../images/logo-senac.png')} className="logo-img-top" alt="Logo Senac" />
                </div>
                <div className="div-social-links">
                    <a href="https://www.facebook.com/senacrj" target="_blank" rel="noreferrer">
                        <FaFacebookSquare className="icon-social-links" />
                    </a>
                    <a href="https://br.linkedin.com/school/senac-rj" target="_blank" rel="noreferrer">
                        <FaLinkedin className="icon-social-links" />
                    </a>
                    <a href="https://www.youtube.com/@SenacRJ" target="_blank" rel="noreferrer">
                        <FaYoutubeSquare className="icon-social-links" />
                    </a>
                    <a href="https://www.instagram.com/senac_rj" target="_blank" rel="noreferrer">
                        <FaInstagramSquare className="icon-social-links" />
                    </a>
                </div>
            </div>
            <div className="links-top-div">
                <Col className="links-top">
                    <a className="link-top-item" href="/">Home</a>
                </Col>
                <Col className="links-top">
                    <a className="link-top-item" href="#faq-campus" onClick={handleButtonFAQClick}>FAQ/Tutorial</a>
                </Col>
                <Col className="links-top">
                    <a className="link-top-item" href="https://www.rj.senac.br/" target="_blank" rel="noreferrer">Site Senac</a>
                </Col>
                <Col className="links-top">
                    <a href="#" className="link-top-item" onClick={handleButtonClick}>
                        Suporte
                        <MdKeyboardArrowDown className={`arrow-icon  ${isArrowUpIcon ? "rotate-icon-suporte" : ""}`} />
                    </a>
                </Col>
            </div>
            <div className={`links-suporte-div ${isArrowUp ? "show-suporte-div" : ""}`}>
                <ul className="links-suporte-list">
                    {switchRenderMenu(isTypeRender)}
                </ul>
            </div>
            {!props.showForm ?
                <div className="div-btn-actions">
                    <Button variant="primary" onClick={props.handleShowForm} className="btns-action-center btn-action-first-access">
                        <HiOutlineUserCircle className="icon-btn-action-first-access" />{' '} Primeiro Acesso
                    </Button>
                    <Button variant="secondary" onClick={() => window.location.href = 'https://www.campusdigital.rj.senac.br'} className="btns-action-center btn-action-campus">
                        <BsBoxArrowInRight className="icon-btn-action-campus" />{' '} Entrar no Campus Digital
                    </Button>
                    <span className="text-loginCampus">Necessário ter o login criado!</span>

                </div>
                :
                <div className="div-btn-actions">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <InputGroup className="group-first-access">
                            <Form.Label className="label-first-access">Digite o seu primeiro nome:</Form.Label>
                            <Form.Control
                                required
                                className="input-first-access"
                                value={modelFirstAccess?.name}
                                id="name"
                                onChange={({ target }) => handleInputChange(target)}
                                placeholder="Primeiro Nome"
                                aria-label="Primeiro Nome"
                                aria-describedby="name"
                            />
                        </InputGroup>
                        <InputGroup className="group-first-access">
                            <Form.Label className="label-first-access">Digite o seu CPF:</Form.Label>
                            <Form.Control
                                required
                                className="input-first-access"
                                value={modelFirstAccess?.cpf}
                                id="cpf"
                                onChange={({ target }) => handleInputChange(target)}
                                placeholder="CPF"
                                aria-label="CPF"
                                aria-describedby="cpf"
                            />
                        </InputGroup>
                        <div id="recaptcha"></div>

                        <Button type="submit" disabled={disabledBtnSubmit} variant="primary" className="send-btn">
                            Enviar
                        </Button>
                    </Form>
                    <a href="#" onClick={props.handleShowForm} className="btn-back-form">
                        <AiOutlineLeft className="left-icon-back" />
                        Voltar
                    </a>
                </div>
            }
            <div className="div-bottom-side">
                <div className="div-log-top">
                    <img src={require('../images/logo-white.png')} className="logo-img-bot" alt="Logo Senac" />
                </div>
                <div className="div-rights">
                    <span>
                        © Todos os Direitos Reservados - 2022<br />
                        Campus Digital - Versão 1.0
                    </span>
                </div>
            </div>
            {showModalStudent &&
                <ModalFirstAccess loadGetStudentApi={loadGetStudentApi} show={showModalStudent} responseDataStudent={responseDataStudent} handleClose={handleClose} />
            }
        </Col>
    );
};

export default FirstAccessSide;