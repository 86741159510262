import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ModalFirstAccess = (props: any) => {

    const [nameSectionChoice, setNameSectionChoice] = useState('Qual a sua data de nascimento');
    const [OptinsToSelect, setOptinsToSelect] = useState<any>([]);
    const [wrongAnswer, setWrongAnswer] = useState<boolean>(false);
    const [correctAnswer, setCorrectAnswer] = useState<boolean>(false);

    const generateRandomDate = (): string => {
        const start = new Date(1970, 0, 1); // Início do período para geração da data aleatória
        const end = new Date(2010, 0, 1); // Fim do período (data atual)
        const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        const day = randomDate.getDate().toString().padStart(2, '0');
        const month = (randomDate.getMonth() + 1).toString().padStart(2, '0');
        const year = randomDate.getFullYear().toString();

        return `${day}/${month}/${year}`;
    };

    const generateObjects = (typeChoice: string) => {
        const objects = [];
        for (let i = 0; i < 5; i++) {
            const option = generateRandomDate();
            const object = { typeChoice, option };
            objects.push(object);
        }

        const objectUser = {
            typeChoice: typeChoice,
            option: props.responseDataStudent?.data_nascimento
        }
        const randomNumber = Math.floor(Math.random() * 5);
        objects[randomNumber] = objectUser;

        return objects;
    };

    const generateMomObjects = (arrayOption: any[], typeChoice: string) => {
        const objects = [];
        for (let i = 0; i < 5; i++) {
            const option = arrayOption[i];
            const object = { typeChoice, option };
            objects.push(object);
        }

        const objectUser = {
            typeChoice: typeChoice,
            option: props.responseDataStudent?.primeiro_nome_mae
        }

        const randomNumber = Math.floor(Math.random() * 5);
        objects[randomNumber] = objectUser;

        return objects;
    };

    const getRandomNames = (data: { nome: string }[], count: number): string[] => {
        const shuffledData = [...data];
        for (let i = shuffledData.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
        }
        return shuffledData.slice(0, count).map(item => item.nome);
    };

    const alterSectionChoice = async (choiceSelect: string) => {
        switch (choiceSelect) {
            case 'birthdate':
                const generatedObjects = generateObjects(choiceSelect);
                setOptinsToSelect(generatedObjects)
                setNameSectionChoice('Qual a sua data de nascimento?')
                break;
            case 'nameMom':
                setNameSectionChoice('Qual nome da sua mãe?')
                const response = await fetch('/names.json');
                const data = await response.json();
                const names = getRandomNames(data.res, 5);
                const generatedObjectsMom = generateMomObjects(names, choiceSelect)
                setOptinsToSelect(generatedObjectsMom);
                break;

        }
    }

    const handleSelectOption = (choiceSelect: string, optionSelected: string) => {
        let selectedCorrectOption;
        switch (choiceSelect) {
            case 'birthdate':
                selectedCorrectOption = optionSelected === props.responseDataStudent?.data_nascimento;
                if (!selectedCorrectOption) {
                    setWrongAnswer(true)
                } else {
                    alterSectionChoice('nameMom')
                }
                break;
            case 'nameMom':
                selectedCorrectOption = optionSelected === props.responseDataStudent?.primeiro_nome_mae;
                if (!selectedCorrectOption) {
                    setWrongAnswer(true)
                } else {
                    setCorrectAnswer(true)
                }
                break;

        }
    }


    useEffect(() => {
        if (!props.responseDataStudent) {
            setWrongAnswer(true)
        }else{
            setWrongAnswer(false)
        }
        alterSectionChoice('birthdate')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.responseDataStudent])


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="h5">{nameSectionChoice}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {wrongAnswer ?
                        <div>
                            <div>
                                <img src={require('../images/logo-senac.png')} className="logo-img-top" alt="Logo Senac" />
                            </div>
                            <div className="menssage-wrong">
                                <span>
                                    Nenhuma informação foi encontrada em nossa base de dados. <br />
                                    Verifique se os campos foram preenchidos corretamente ou entre em contato através do e-mail ou do celular. 
                                    <br />
                                    <a href='mailto:suporte.digital@rj.senac.br'>suporte.digital@rj.senac.br</a>
                                    <br />
                                    <a href="https://whatsa.me/5521995379347"> (21) 99537-9347</a>
                                </span>
                            </div>

                        </div>
                        :
                        <>
                            {props.loadGetStudentApi ?
                                <>carregando</>
                                :
                                <>
                                    {correctAnswer ?
                                        <div>
                                            <div>
                                                <img src={require('../images/logo-senac.png')} className="logo-img-top" alt="Logo Senac" />
                                            </div>
                                            <div className="menssage-correct">
                                                <div className="name-student-modal">
                                                    <strong>
                                                        Olá, {props.responseDataStudent.nome_aluno}
                                                    </strong>
                                                </div>
                                                <span>
                                                    Seguem suas credenciais para acessar o Campus Digital.<br />
                                                    No seu primeiro acesso, será solicitado que você altere sua senha. Coloque uma senha segura* e não esqueça, essa conta será seu meio de acesso à nossa plataforma.
                                                </span>
                                                <div className="dataStudentUser ">
                                                    <span>
                                                        <strong>E-mail: </strong>
                                                        {props.responseDataStudent.email}
                                                    </span>
                                                    <span>
                                                        <strong>Senha: </strong>
                                                        {props.responseDataStudent.senha}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        *Sua nova senha precisará ter no mínimo 8 caracteres e preencher pelo menos 3 requisitos dos 4 listados abaixo:
                                                    </span>
                                                    <ul>
                                                        <li>Letra maiúscula</li>
                                                        <li>Letra minúscula</li>
                                                        <li>Caracter numérico</li>
                                                        <li>Caracter especial (%, #, $, !, etc)</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <a href="https://www.campusdigital.rj.senac.br" className="btn-link-campus">Clique aqui para fazer o seu login</a>
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <>
                                            {OptinsToSelect.map((x: any) => (
                                                <div id={x.typeChoice} className="input-select-choice" onClick={() => handleSelectOption(x.typeChoice, x.option)}>{x.option}</div>
                                            ))}
                                        </>
                                    }

                                </>
                            }
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalFirstAccess;