/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import FaqSide from './FaqSide';
import CampusSide from "./CampusSide";
// import ModalFirstAccess from "./ModalFirstAccess";
import FirstAccessSide from "./FirstAccessSide";
import ReCAPTCHA from "react-google-recaptcha";

const InitialPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const [initialHeight, setInitiaHeight] = useState(0);
  const [nextHeight, setNextHeight] = useState(0);
  const [heightMargin, setHeightMargin] = useState(0);

  useEffect(() => {
    const tamanho: any = document.getElementsByClassName('div-btn-actions')[0];
    setInitiaHeight(tamanho?.offsetHeight)
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      const tamanho: any = document.getElementsByClassName('div-btn-actions')[0];
      setInitiaHeight(tamanho?.offsetHeight)
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    const tamanho = nextHeight - initialHeight;
    setHeightMargin(tamanho)
  }, [nextHeight])

  const handleShowForm = () => {
    setShowForm(!showForm);
    if (showForm) {
      setNextHeight(initialHeight)
    }
  };

  return (
    <Container fluid>
      <Row>
        <FirstAccessSide
          setNextHeight={setNextHeight}
          handleShowForm={handleShowForm}
          showForm={showForm}
          showFAQ={showFAQ}
          setShowFAQ={setShowFAQ}
        />
        {showFAQ ?
          <FaqSide />
          :
          <CampusSide
            marginValue={heightMargin}
          />
        }
      </Row>
      <ReCAPTCHA
        sitekey="6LfeG0ImAAAAAOKbRNgZnLNCBBQa0RD6KFJLmBdd"
        style={{ display: 'none' }}
      />
      {/* <ModalFirstAccess show={show} handleClose={handleClose} /> */}
    </Container>
  );
};

export default InitialPage;