import { Accordion, Col } from "react-bootstrap";

const FaqSide = () => {

    return (
        <Col lg={8} md={7} sm={6} className="div-faq-campus" id="faq-campus">
            <div className="circle-image-center-campus-faq">
                <img className="img-group-7" src={require('../images/Group7.png')} alt="circle center" />
            </div>
            <div className="box-top-campus-faq d-flex">
                <div className="box-top-text-faq">
                    <span className="text-campus-digital-faq">
                        <strong className="text-campus">Campus</strong>Digital
                    </span>
                    <h5>FAQs e Tutoriais</h5>
                </div>
            </div>
            <div className="box-bottom-campus-faq d-flex">
                <Accordion>
                    <h6 className="title-faq" id="Acessos">Acessos</h6>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Fiz minha matrícula em uma turma que ainda irá começar. Já consigo acessar a plataforma?</Accordion.Header>
                        <Accordion.Body>
                            O acesso do aluno à plataforma é liberado 7 dias corridos antes do início do curso.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Fiz minha matrícula em uma turma já em execução. Já consigo acessar a plataforma? </Accordion.Header>
                        <Accordion.Body>
                            Não. O acesso do aluno às turmas em execução é liberado somente no dia seguinte à sua matrícula.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Como faço para acessar a plataforma? </Accordion.Header>
                        <Accordion.Body>
                            <p> Para acessar aos seus aplicativos do Office 365 entre em <a href="http://office.com" target="_blank" rel="noreferrer">http://office.com</a></p>
                            <p> Para acessar ao seu e-mail educacional entre em <a href="https://outlook.office.com" target="_blank" rel="noreferrer">https://outlook.office.com</a></p>
                            <p> Para acessar ao ambiente virtual entre em <a href="https://teams.microsoft.com" target="_blank" rel="noreferrer">https://teams.microsoft.com</a></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Realizei o acesso com a minha senha de primeiro acesso, mas não consigo inserir uma senha válida para alterar.</Accordion.Header>
                        <Accordion.Body>
                            <p>A sua senha precisa atender a três requisitos dos 4 listados abaixo:
                            </p>
                            <ul>
                                <li>Letra maiúscula</li>
                                <li>Letra minúscula</li>
                                <li>Caractere especial </li>
                                <li>Números </li>
                            </ul>
                            <p>
                                <i>Exemplos:</i>
                            </p>
                            <ol>
                                <li>#Meunome571 </li>
                                <li>Meunome2021 </li>
                                <li>Minh@senha  </li>
                                <li>senh@especi@l92  </li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Acessei com minha senha de primeiro acesso. O que devo fazer primeiro?</Accordion.Header>
                        <Accordion.Body>
                            <p>Quando você acessa ao ambiente virtual pela primeira vez, é solicitado que você crie uma senha nova. É importante que você não esqueça essa senha.</p>
                            <p>  É solicitado também que você realize a autenticação da sua conta. Você tem um período de 15 dias a partir do primeiro acesso para realizar a autenticação. Para saber mais sobre, acese a nossa seção Autenticação.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Não consigo acessar com a minha senha. O que faço?</Accordion.Header>
                        <Accordion.Body>
                            <p>Caso você <b>nunca</b> tenha acessado seu ambiente virtual, a sua senha para primeiro acesso está disponível para consulta no portal de Primeiro Acesso.</p>
                            <p>  Caso você já tenha alterado sua senha de primeiro acesso, utilize a nova senha que você criou.</p>
                            <p>  E caso você já tenha alterado a sua senha e esqueceu qual escolheu, você pode recuperá-la clicando em "esqueci minha senha" na tela de realização de login. Para utilizar esse recurso é necessário que você já tenha cadastrado um método de autenticação. Para saber mais sobre, acese a nossa seção Autenticação.  </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Não cadastrei um método de autenticação, como faço pra recuperar minha senha?</Accordion.Header>
                        <Accordion.Body>
                            <p>Entre em contato com nosso suporte através dos nossos canais de comunicação:
                                <br /> E-mail:
                                <a href="mailto:suporte.digital@rj.senac.br"> suport.digital@rj.senac.br</a>
                                <br />  Whatsapp:
                                <a href="https://whatsa.me/5521995379347"> (21) 99537-9347</a>
                                <br />
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>O que consigo acessar dentro da plataforma? </Accordion.Header>
                        <Accordion.Body>
                            <p>Dentro da plataforma Microsoft 365 você consegue acessar todos os aplicativos da Microsoft (Word, Excel, PowerPoint, OneNote, Outlook, entre outros). Dentro do Teams você consegue acessar o Campus Digital - nosso portal de serviços exclusivo pro aluno com as informações do seu curso, com a agenda das suas próximas aulas, com links úteis. Além de também conseguir acessar suas equipes, seu calendário, atividades, tarefas pendentes. É basicamente seu meio de comunicação com os seus professores, a instituição e seus colegas. </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Acessei a plataforma, mas não encontro minhas turmas. </Accordion.Header>
                        <Accordion.Body>
                            <p>Se você faz um curso com mais de uma disciplina, as disciplinas previstas só começarão a aparecer para você 7 dias do início da mesma. Caso já esteja dentro do período de 7 dias e ainda não apareceu, é necessário aguardar o professor liberar o acesso dos alunos à disciplina. Assim que o professor fizer a liberação, a turma irá aparecer. </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Cancelei minha matrícula, continuo conseguindo acessar à plataforma? </Accordion.Header>
                        <Accordion.Body>
                            <p>Não. Seu acesso é encerrado no dia seguinte ao seu cancelamento.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Terminei meu curso, continuo conseguindo acessar à plataforma?</Accordion.Header>
                        <Accordion.Body>
                            <p>Seu acesso será válido até 15 dias úteis após o fim do seu curso. Aproveite esse período para salvar algum documento que você queira, algum material da turma, assistir alguma aula gravada, realizar o download dos seus arquivos, entre outras coisas.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>Fui aluno anteriormente e voltei pro Senac. Minha conta é a mesma?</Accordion.Header>
                        <Accordion.Body>
                            <p>Sim. Seu usuário continua sendo o mesmo e a sua senha continuará sendo a mesma desde o seu último acesso.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <h6 className="title-faq" id="Autenticacao">Autenticação</h6>
                    <Accordion.Item eventKey="12">
                        <Accordion.Header>O que é um método de Autenticação?</Accordion.Header>
                        <Accordion.Body>
                            <p>Um dos principais recursos de uma plataforma de identidade é verificar ou autenticar as credenciais quando um usuário entra em um dispositivo, um aplicativo ou um serviço. Para aprimorar a segurança e reduzir a necessidade de suporte técnico, os métodos de autenticação são necessários para confirmar a identidade de um usuário, dando autonomia para o mesmo realizar o reset da sua senha através do Portal de Autoatendimento do Microsoft 365.  </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header>Quais métodos de Autenticação que posso cadastrar?</Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li>E-mail pessoal</li>
                                <li>Celular </li>
                                <li>Aplicativo Microsoft Authenticator. (Para saber mais sobre o aplicativo,
                                    <a href="https://support.microsoft.com/pt-br/account-billing/como-usar-o-aplicativo-microsoft-authenticator-9783c865-0308-42fb-a519-8cf666fe0acc" target="_blank" rel="noreferrer">clique aqui</a>.)
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header>Como posso realizar minha autenticação?</Accordion.Header>
                        <Accordion.Body>
                            <p>Quando surgir a mensagem para autenticar, caso queira utilizar o aplicativo Microsoft Authenticator, clique em próximo. Caso queira utilizar outro meio, selecione a opção "Desejo configurar um método diferente". Escolha qual método você prefere usar - Telefone ou e-mail.
                                <br />  - Se optar por telefone, digite seu número e escolha se prefere receber um SMS ou uma ligação, clique em próximo. Após isso, será informado via mensagem ou ligação um código de 6 digítos. Digite o código recebido no seu ceular e clique em próximo. Pronto, seu celular está associado à sua conta Senac.
                                <br />  - Se optar por e-mail, digite um e-mail pessoal que você utilize e clique em próximo. Após isso, você receberá nesse e-mail uma mensagem informando um código de 6 dígitos. Digite o código recebido no seu e-mail e clique em próximo. Pronto, seu e-mail pessoal está associado à sua conta Senac.
                            </p>
                            <p>Clique em concluído.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header>Não quero autenticar minha conta. Tem algum problema?</Accordion.Header>
                        <Accordion.Body>
                            <p>Por padrão, você tem 15 dias para realizar a autenticação. Durante esse período, você pode pular essa etapa. A partir décimo quinto dia, você será obrigado a cadastrar um método de autenticação. Então sim, o problema é que você não conseguirá mais acessar sua conta e, por consequência, usufruir do seu ambiente online.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <h6 className="title-faq" id="Tutoriais">Tutoriais</h6>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header>Tutorial de acesso ao Portal de Serviços</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                <a href="http://www.rj.senac.br/wp-content/uploads/2022/02/Tutorial-de-acesso-ao-Campus-Digital-para-Alunos_V.Final-CFCD.pdf" target="_blank" rel="noreferrer">
                                    Clique aqui para acessar ao tutorial
                                </a>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="17">
                        <Accordion.Header>Guias de acesso</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                <a href="/assets/Guia%20de%201%C2%BA%20acesso%20Campus%20Digital%20-%20E-mail%20MS%20Authenticator%20Ver%2001.pdf" target="_blank" rel="noreferrer">Guia de 1º acesso Campus Digital - E-mail MS Authenticator</a>  
                            </p>
                            <p>
                                <a href="/assets/Guia%20de%201%C2%BA%20acesso%20Campus%20Digital%20-%20Telefone%20MS%20Authenticator%20Ver%2001.pdf" target="_blank" rel="noreferrer">Guia de 1º acesso Campus Digital - Telefone MS Authenticator</a>
                            </p>
                            <p>
                                <a href="/assets/Guia%20de%201%C2%BA%20acesso%20Campus%20Digital%20-%20APP%20MS%20Authenticator%20Ver%2001.pdf" target="_blank" rel="noreferrer">Guia de 1º acesso Campus Digital - APP MS Authenticator</a>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="18">
                        <Accordion.Header>Tutorial Alunos(as) Resilia</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                <a href="/files/senac_tutorial_online.pdf" target="_blank">
                                    Clique aqui para acessar ao tutorial
                                </a>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </Col>
    );
};

export default FaqSide;