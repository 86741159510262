import FirstAcessRepository from "../repository/FirstAcessRepository";


interface UserFirtsAccess {
    name?: string;
    cpf?: string;
}


export default class FirstAcessServiceService {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async getStudentApi(studentModel: UserFirtsAccess): Promise<any> {

        const itensLista = await new FirstAcessRepository().getStudentApi(studentModel);
        return itensLista;
    }
}
