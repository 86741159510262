import './portal.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InitialPage from './components/InitialPage';

function App() {
  return (
    <>
      <InitialPage />
    </>
  );
}

export default App;
