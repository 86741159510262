import { Col } from "react-bootstrap";
import { BsWhatsapp } from "react-icons/bs";

const CampusSide = (props: any) => {

    return (
        <Col lg={8} md={7} sm={6} className="div-banner-campus">
            <div className="circle-image-center-campus">
                <img className="img-group-7" src={require('../images/Group7.png')} alt="circle center" />
            </div>
            <div className="box-top-campus d-flex">
                <div className="circle-image-top-campus">
                    <img src={require('../images/Group6.png')} alt="center top" />
                </div>
                <div className="box-top-text">
                    <h5>Seja bem-vindo(a) ao</h5>
                    <span className="text-campus-digital">
                        <strong className="text-campus">Campus</strong>
                        <br />Digital
                    </span>
                </div>
            </div>
            <div className="box-bottom-campus d-flex">
                <div className="div-wpp-position">
                    <div className="div-wpp">
                        <a href="https://whatsa.me/5521995379347" target="_blank" rel="noreferrer" className="icon-wpp-bottom-div">
                            <BsWhatsapp className="icon-wpp-bottom" />
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <img style={{ marginTop: props.marginValue }} className="girl-campus-img" alt="girl" src={require('../images/girl-otimizado.png')} />
            </div>
        </Col>
    );
};

export default CampusSide;