/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-atomic-updates */

import axios from 'axios';

interface UserFirtsAccess {
    name?: string;
    cpf?: string;
}

export default class FirstAcessRepository {

    public async getStudentApi(studentModel: UserFirtsAccess): Promise<any> {
        let responseAluno: any;
        const url = `https://as.rj.senac.br/campus/api/rest/rev1/aluno?username=BigBrainUser&password=ew67mut5dfc98dx`;
        await axios.get(url, {
            params: {
                primeiro_nome: studentModel.name,
                cpf: studentModel.cpf
            }
        }
        ).then(data => {
            responseAluno = data.data.aluno
        }).catch(error => {
            console.log(error)
        })

        return responseAluno;
    }

}